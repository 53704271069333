import { ButtonLabel, HeadingLabel, SubheadingLabel } from '@/styles/fonts'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { GrandstandButton } from '../Buttons'

const ButtonText = styled(ButtonLabel)`
  text-transform: uppercase;
`

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99;
`

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 528px;
  transform: translate(-50%, -50%);
  color: var(--surface--light);
  background-color: var(--web-dark-surface-light, #fff);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
  text-align: center;
  gap: 20px;
  z-index: 100;
`

const ModalHeading = styled(HeadingLabel)`
  color: var(--on-light);
`

const ModalLabel = styled(SubheadingLabel)`
  color: var(--on-light);
`

const ModalButton = styled(GrandstandButton)`
  width: 100%;
  border-radius: 4px;
  ${({ buttonStyle }) => {
    if (buttonStyle === 'primary') {
      return css`
        background-color: var(--web-dark-canvas, #000);
        color: var(--web-light-on-button, #fff);
      `
    } else if (buttonStyle === 'alt') {
      return css`
        background-color: var(--web-dark-surface-light, #fff);
        color: var(--web-light-on-button-alt, #000);
        border: 1px solid var(--web-light-button-alt, rgba(0, 0, 0, 0.2));
      `
    }
  }}
  &:hover {
    background-color: gray;
  }
`

const GoFavoritesModal = ({ onClose }: any) => {
  const router = useRouter()
  const handleGoFavorites = () => {
    router.push('/settings/favorites')
  }

  return (
    <ModalBackground onClick={onClose}>
      <ModalContent>
        <ModalHeading fontSize="3" mobileFontSize="2">
          Let there be sports.
        </ModalHeading>
        <ModalLabel fontSize="1">
          Welcome to the Team. To get the most out of our products, set your favorite teams for a customized experience.
        </ModalLabel>
        <ModalButton buttonStyle="primary" buttonSize="sm" onClick={handleGoFavorites}>
          <ButtonText fontSize="sm">SET FAVORITES</ButtonText>
        </ModalButton>
        <ModalButton buttonStyle="alt" buttonSize="sm" onClick={onClose}>
          <ButtonText fontSize="sm">SKIP</ButtonText>
        </ModalButton>
      </ModalContent>
    </ModalBackground>
  )
}

export default GoFavoritesModal
